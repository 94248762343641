fgs = Object.assign(fgs, function() {
  return {
    handleElement: function(id) {
      if (document.getElementById(id).classList.contains('hidden')) {
        document.getElementById(id).classList.remove('hidden');
      } else {
        document.getElementById(id).classList.add('hidden');
      }
    },

    scrollTo: function(id) {
      window.scrollTo({
        top: document.getElementById(id).offsetTop - 61,
        behavior: "smooth"
      });
    },
  }
}());
