fgs = Object.assign(fgs, function() {
  return {
    handleNavbarMenu: function() {
      if (document.getElementsByClassName('content__initial')[0].classList.contains('hidden')) {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        document.getElementsByClassName('content__initial')[0].classList.remove('hidden');
        document.getElementsByClassName('content__final')[0].style.position = 'fixed';
      } else {
        document.getElementsByClassName('content__initial')[0].classList.add('hidden');
        document.getElementsByClassName('content__final')[0].style.position = '';
      }
    },
  }
}());
