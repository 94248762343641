fgs = Object.assign(fgs, function() {
  return {
    setVariables: function(source) {
      // fgs.variables += Object.assign(fgs.variables, source);
      Object.keys(source).forEach(function(key) {
        fgs.variables[key] = source[key];
      });
    },

    variables: Object({})
  }
}());
